import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import css from './Sections.module.css';

import lopezIcon from './SectionIcons/lopez.jpg';
import bainbird from './SectionIcons/bainbridge.jpg';
import washington from './SectionIcons/washington.jpg';
import quartermaster from './SectionIcons/quartermaster.png';

function SectionDestinations() {
  const intl = useIntl();
  const history = useHistory();

  const sections = [
    {
      icon: lopezIcon,
      title: intl.formatMessage({
        id: 'SectionDestinations.LopezIsland',
      }),
      search:
        's?address=San%20Juan%20Island%2C%20Washington%2C%20United%20States&bounds=48.624292%2C-122.959213%2C48.45012%2C-123.176858&search=San%20Juan%20Island%2C%20Washington%2C%20United%20States',
    },
    {
      icon: bainbird,
      title: intl.formatMessage({
        id: 'SectionDestinations.BainbridgeIsland',
      }),
      search:
        's?address=Bainbridge%20Island%2C%20Washington%2C%20United%20States&bounds=47.730691%2C-122.45247%2C47.5575%2C-122.603&search=Bainbridge%20Island%2C%20Washington%2C%20United%20States',
    },
    {
      icon: washington,
      title: intl.formatMessage({
        id: 'SectionDestinations.SuciaIslands',
      }),
      search:
        's?address=Seattle%2C%20Washington%2C%20United%20States&bounds=47.82647515%2C-122.0321988%2C47.43474439%2C-122.44148153&mapSearch=true&search=Seattle%2C%20Washington%2C%20United%20States',
    },
    {
      icon: quartermaster,
      title: intl.formatMessage({
        id: 'SectionDestinations.BainbridgeIslands',
      }),
      search:
        '/s?address=Vashon%20Island%20Sportsmen%27s%20Club%2C%20Vashon%2C%20Washington%2098070%2C%20United%20States&bounds=47.42212089%2C-122.42226517%2C47.38495815%2C-122.49217329&mapSearch=true&search=Vashon%20Island%20Sportsmen%27s%20Club%2C%20Vashon%2C%20Washington%2098070%2C%20United%20States',
    },
  ];

  return (
    <div className={css.destinationSection}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2>
              {intl.formatMessage({
                id: 'SectionDestinations.title',
              })}
            </h2>
          </div>
        </div>
        <div className={css.destinationBlock}>
          {sections.map((section, index) => (
            <div
              className={css.moreInfoBlock}
              key={index}
              onClick={() => history.push(section?.search)}
            >
              <img src={section.icon} alt={section.title} />
              <div className={css.destinationContent}>
                {section.title && <h4>{section.title}</h4>}
                {section.description || (true && <p>{section.description}</p>)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SectionDestinations;
