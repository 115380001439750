import React, { useEffect } from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../../../util/reactIntl';
import {
  Button,
  FieldLocationAutocompleteInput,
  FieldTextInput,
  Form,
} from '../../../../components';

import css from './LandingSearchForm.module.css';
import IconSearchDesktop from '../../../NotFoundPage/SearchForm/IconSearchDesktop';

const identity = v => v;

const LandingSearchFormComponent = props => {
  const { viewport } = props;
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          values,
          intl,
          isSearchPageFilter,
          handleSubmit,
          submitInProgress = false,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className, {
          [css.searchPageFilter]: isSearchPageFilter,
        });

        const isMobileLayout = viewport.width > 0 && viewport.width < 768;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FieldLocationAutocompleteInput
              rootClassName={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              label=""
              placeholder={intl.formatMessage({
                id: 'LandingSearchForm.locationPlaceholder',
              })}
              useDefaultPredictions={true}
              format={identity}
              valueFromForm={values.location}
            />
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => {
                const location = values?.location;
                if (location?.selectedPlace?.origin?.lat) {
                  props.onSubmit(values);
                }
              }}
            />
            <Button
              className={classNames({
                [css.searchIcon]: isSearchPageFilter,
                [css.searchBtn]: !isSearchPageFilter,
              })}
              type="submit"
              inProgress={submitInProgress}
              onClick={() => props.onSubmit(values)}
            >
              <IconSearchDesktop /> {isMobileLayout ? '' : 'Search'}
            </Button>
          </Form>
        );
      }}
    />
  );
};

LandingSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LandingSearchForm = injectIntl(LandingSearchFormComponent);

export default LandingSearchForm;
